import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
import Mainheader from '../Component/Homes/mainheader/Mainheader';
import About from "../Component/Homes/About/About";
import Industry from "../Component/Homes/Industry/Industry";
import Counter from "../Component/Homes/Counter/Counter";
import Client from "../Component/Homes/Clients/Client";
import Project from "../Component/Homes/Projects/Project";
import Work from "../Component/Homes/Works/Work";
import Tesimonials from "../Component/Homes/Tesimonials/Tesimonials";
import Form from "../Component/Homes/Form/Form";
export default function HomePage() {
  return (
    <>
      <Header />
      <Mainheader />
      <About />
      <Industry />
      <Counter />
      <Client />
      <Project />
      <Work />
      <Tesimonials />
      <Form />
      <Footer1 />
      <Footer />
    </>
  );
};
