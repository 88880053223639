import React, { useEffect, useState } from "react";
import "./Tesimonials.css";
import { Container } from "react-bootstrap";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import team from "../../../assets/images/user.png";
import axios from "axios";

function Tesimonials() {

  const [testimonialsdata, settestimonialsdata] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/gettestimonials`)
      .then((response) => {
        if (response.status == 200) {
          settestimonialsdata(response.data.testimoniallist)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])


  return (
    <section className="section-Tesimonials">
      <Container>
        <div className="tesimonials-30">
          <div className="section-title">
            <h2>Success Stories from Our Clients</h2>
            <div className="bar"></div>
            <p>
              We are proud to have built not only successful business partnerships but also genuine friendships with our clients.
              <br />
              Here’s what they have to say about their experience working with us:
            </p>
          </div>
        </div>
        <Swiper
          slidesPerView={2}
          delay="4000"
          modules={[Autoplay]}
          autoplay={true}
          spaceBetween={10}
          breakpoints={{
            768: { slidesPerView: 2 },
            767: {
              slidesPerView: 1,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          className="mySwiper"
        >
          {testimonialsdata.map((element, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="item">
                  <div className="client">
                    <div className="client-image">
                      <img className="team" src={team} alt="map-imgg" />
                    </div>
                    <p className="client-say">
                      {element.comments}
                    </p>
                    <h4 className="client-say">{element.name}</h4>
                    <p>{element.post}</p>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Container>
    </section>

  );
}

export default Tesimonials;
