import React, { useEffect } from "react";
import Rqform from "./Rqform";
import "./Request.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";

function Request() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="request-form">
        <Container>
          <div className="request" data-aos="zoom-in">
            <h3>Request a quote to get started on <br /> your project with us!</h3>
            <p>Initiate a conversation with us today.</p>
          </div>
        </Container>
      </div>
      <div className="request-form-1">
        <Container>
          <Row>
            <Col lg={5} md={12} sm={12}>
              <div className="request-form-2">
                <p>
                  We are eager to collaborate with you. Please share some details about yourself and your project, and we'll provide a quote and time estimate.
                </p>
              </div>
              <div className="steps">
                <div className="step">
                  <div className="step-header">
                    <div className="step-1">1</div>
                    <h4>Proposal Preparation:</h4>
                    <p>
                      We will create a proposal that outlines the scope, timeline, and estimated pricing based on the detailed information you provide about your project.t
                    </p>
                  </div>
                </div>
                <div className="step minimized">
                  <div className="step-header">
                    <div className="step-1">2</div>
                    <h4>Collaborative Discussion:</h4>
                    <p>
                      Let’s connect to explore all potential options and solutions. A requirements gathering meeting is the best way to ensure alignment.
                    </p>
                  </div>
                </div>
                <div className="step minimized-1">
                  <div className="step-header">
                    <div className="step-1">3</div>
                    <h4>Project Initiation:</h4>
                    <p>
                      After the contract is signed and all objectives are set, we will commence the first sprint.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7} md={12} sm={12}>
              <Rqform />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="banner-slide">
        <Container>
          <Row>
            <Col md={12} sm={12}>
              <div className="banner-slide-1">
                <h5>Optimized Software Performance</h5>
                <p>
                  Performance is a crucial aspect of a system that can limit its overall usability. As our underlying technologies evolve, the factors affecting performance may change accordingly.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Request;
