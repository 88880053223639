import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
import Careers from "../Component/Career/Careers";
function Career() {
  return (
    <>
      <Header />
      <Careers />
      <Footer1 />
      <Footer />
    </>
  );
};

export default Career;
