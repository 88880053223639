import React from 'react'
import "./Work.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaFileAlt, FaViruses, FaMedal } from "react-icons/fa";
import works1 from "../../../assets/images/home/works1.png";
export default function Work() {
  return (
    <>
      <section className="how-text">
        <Container>
          <div className="work">
            <div className="section-title">
              <h2>How It Works</h2>
              <div className="bar"></div>
            </div>
            <p>
              System development typically involves several stages: planning, analysis, design, implementation, quality assurance, and deployment. Maintenance is also a critical component that should not be overlooked, as it ensures the continued effectiveness and reliability of the system.
            </p>
          </div>
          <div className="work-steps-box">
            <Row>
              <Col md={6}>
                <div className="work-steps-detail">
                  <div className="steps-icon">
                    <i>
                      <FaFileAlt />
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4> Planning & Analysis</h4>
                    <p className="step-desc">
                      Planning and analysis clarify the project's scope, issues, and opportunities, while requirements gathering ensures precise and detailed needs are collected.
                    </p>
                  </div>
                </div>
                <div className="work-steps-detail mt-4">
                  <div className="steps-icon">
                    <i>
                      <FaViruses />
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4> Design and Implementation</h4>
                    <p className="step-desc">
                      This phase focuses on implementing a user-friendly system that enhances productivity, ensuring performance, security, and ease of use.
                    </p>
                  </div>
                </div>
                <div className="work-steps-detail  mt-4">
                  <div className="steps-icon">
                    <i>
                      <FaMedal />
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4> Quality Analysis and Deployment</h4>
                    <p className="step-desc">
                      We recognize nothing is perfect, but we strive for continuous improvement. Quality assurance is crucial before reaching our customers.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="wor-img" data-aos="zoom-in">
                  <img src={works1} alt="img7" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}
