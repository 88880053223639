import React, { useState, useEffect } from "react";
import "./Mainheader.css";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import service1 from "../../../assets/images/home/service1.png";
import service2 from "../../../assets/images/home/service2.png";
import service3 from "../../../assets/images/home/service3.png";
import service4 from "../../../assets/images/home/service4.png";
import axios from "axios";

function Mainheader() {

  const [sliderdata, setSliderdata] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getslider`)
      .then((response) => {
        if (response.status == 200) {
          setSliderdata(response.data.sliders)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])


  return (
    <>
      <Swiper
        slidesPerView={1}
        modules={[Autoplay]}
        spaceBetween={10}
        autoplay={true}
        delay="4000"
      >
        {sliderdata.map((element, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="img-top-box">
                <div className="overlay">
                  <div className="carousel-caption">
                    <Col lg={12} md={12} sm={12}>
                      <Animated
                        animationIn="bounceInDown"
                        animationOut="fadeOut"
                        isVisible={true}
                        animationInDuration={2500}
                        animationOutDuration={2500}
                      >
                        <div className="home-slider">
                          <img className="main-image" src={element.photo} alt="" />
                          <div className="home-all-text">
                            <h1 className="home-text">{element.title}</h1>
                            <p className="home-paragraph">{element.description}</p>
                            <button className="home-btn">
                              <Link to="/Contact">
                                Get In Touch &nbsp;
                              </Link>
                            </button>
                          </div>
                        </div>
                      </Animated>
                    </Col>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}

      </Swiper>
      {/* //  second section */}
      <div className="section-service">
        <Container>
          <div className="home-page-service-desktop">
            <Row>
              <Col xl={3} md={6} sm={6}>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service1} alt="" />
                  </div>
                  <h3>
                    <a href="#ff">Discuss Your Vision</a>
                  </h3>
                  <p>
                    We are committed to listening to your ideas and transforming them into reality to create a significant impact.
                  </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={6}>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service2} alt="img8" />
                  </div>
                  <h3>
                    <a href="#cv">Solution Analysis</a>
                  </h3>
                  <p>
                    Our analysis is focused on identifying optimal solutions. We ensure that no relevant details are overlooked in the process.
                  </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={6}>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service3} alt="img6" />
                  </div>
                  <h3>
                    <a href="#bcxb">Bring Your Vision to Life</a>
                  </h3>
                  <p>
                    Dedicated to turning your ideas into reality with high-performance systems. We take great pride in making your vision possible.                  </p>
                </div>
              </Col>
              <Col xl={3} md={6} sm={6}>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service4} alt="" />
                  </div>
                  <h3>
                    <a href="#v">Customer-Centric</a>
                  </h3>
                  <p>
                    Deployment is a significant responsibility, committed to ensuring our customers are satisfied with our service.                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="home-page-service-mobile">
            <Swiper
              slidesPerView={1}
              autoplay={{
                "clickable": true
              }}
              spaceBetween={10}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service1} alt="" />
                  </div>
                  <h3>
                    <a href="#ff">Discuss Your Vision</a>
                  </h3>
                  <p>
                    We are committed to listening to your ideas and transforming them into reality to create a significant impact.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service2} alt="img8" />
                  </div>
                  <h3>
                    <a href="#cv">Solution Analysis</a>
                  </h3>
                  <p>
                    Our analysis is focused on identifying optimal solutions. We ensure that no relevant details are overlooked in the process.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service3} alt="img6" />
                  </div>
                  <h3>
                    <a href="#bcxb">Bring Your Vision to Life</a>
                  </h3>
                  <p>
                    Dedicated to turning your ideas into reality with high-performance systems. We take great pride in making your vision possible.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="single-solutions-box">
                  <div className="icon">
                    <img src={service4} alt="" />
                  </div>
                  <h3>
                    <a href="#v">Customer-Centric</a>
                  </h3>
                  <p>
                    Deployment is a significant responsibility, committed to ensuring our customers are satisfied with our service.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Mainheader;
