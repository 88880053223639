import React, { useEffect } from "react";
import "./Counter.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";

function Counter() {
  const [focus, setFocus] = React.useState(false);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className="Counter">
      <Container>
        <div className="counte-img">
          <div className="section-title-counter">
            <h2>
              We prioritize understanding  and valuing customer feedback, which is essential in shaping our decisions and strategies.
            </h2>
            <div className="bar"></div>
            <p>
              We actively seek and incorporate customer feedback to tailor our solutions, ensuring they meet your specific needs and enhance your satisfaction.
            </p>
          </div>
        </div>
        <div className="row text-center">
          <Col lg={3} md={6} sm={6} xs={6}>
            <div className="counter">
              <h3>
                <CountUp
                  start={focus ? 0 : null}
                  end={1.5}
                  duration={5}
                  redraw={true}
                  decimals={1}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <VisibilitySensor
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setFocus(true);
                          }
                        }}
                      >
                        <a>K+Project</a>
                      </VisibilitySensor>
                    </div>
                  )}
                </CountUp>
              </h3>
              <p>Delivered Successfully</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <div className="counter">
              <h3>
                <CountUp
                  start={focus ? 0 : null}
                  end={100}
                  duration={5}
                  redraw={true}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <VisibilitySensor
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setFocus(true);
                          }
                        }}
                      >
                        <a>+Products</a>
                      </VisibilitySensor>
                    </div>
                  )}
                </CountUp>
              </h3>
              <p> We Built so far</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <div className="counter">
              <h3>
                <CountUp
                  start={focus ? 0 : null}
                  end={10}
                  duration={5}
                  redraw={true}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <VisibilitySensor
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setFocus(true);
                          }
                        }}
                      >
                        <a>+Yrs</a>
                      </VisibilitySensor>
                    </div>
                  )}
                </CountUp>
              </h3>
              <p>Of experience</p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <div className="counter">
              <h3>
                <CountUp
                  start={focus ? 0 : null}
                  end={35}
                  duration={5}
                  redraw={true}
                >
                  {({ countUpRef }) => (
                    <div>
                      <span ref={countUpRef} />
                      <VisibilitySensor
                        onChange={(isVisible) => {
                          if (isVisible) {
                            setFocus(true);
                          }
                        }}
                      >
                        <a>+Countries </a>
                      </VisibilitySensor>
                    </div>
                  )}
                </CountUp>
              </h3>
              <p>Wherer We Worked</p>
            </div>
          </Col>
        </div>
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} className="offset-2 mt-5">
            <div className="contact-cta-box">
              <div className="ct-conu">
                <h3>Do you have an idea?</h3>
                <p>
                  Contact us, and we can turn it into reality for you!
                </p>
              </div>
              <div className="mat-text">
                <Button type="Submit" className="main-btn">
                  <Link to="/contact">
                    Contact Us <FaAngleRight />
                  </Link>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Counter;
