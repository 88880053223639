import React from "react";
import "./About.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import about3 from "../../../assets/images/about3.png";

export default function About() {
  return (
    <>
      <div className="about-area">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="home-about-img" data-aos="fade-right">
                <img src={about3} alt="image3" className="img-about" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="Home-about-content"  >
                <span className="home-abouts-30">Do you want to know ?</span>
                <h2 className="about-tile-h2-desktop"><strong>IMPETROSYS</strong> play a crucial role by providing tailored solutions and support to enhance efficiency, productivity, and competitiveness</h2>
                <h2 className="about-tile-h2-mobile"><strong>IMPETROSYS</strong> play a crucial role by providing tailored solutions</h2>
                <div className="bar"></div>
                <p>
                  <strong>Automation</strong>
                  Streamlines tasks, boosts efficiency, and frees employees for higher-value work.
                </p>
                <p>
                  <strong> Cost Savings</strong>
                  Enhances efficiency, reduces waste, and optimizes resources, leading to long-term savings.
                </p>
                <p>
                  <strong>Data Management and Analysis:</strong>
                  Facilitates data collection and analysis for informed decision-making and improved performance.
                </p>
                <p>
                  <strong>Scalability</strong>
                  Adapts to business growth, enabling seamless expansion without major infrastructure changes.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
