import React, { useEffect } from "react";
import "./Career.css";
import Careerform from "./Careerform";
import Vacancy from "./Vacancy"
import homeabut from "../../assets/images/homeabut.png";
import ourproduct1 from "../../assets/images/ourproduct1.jpg";
import { Container, Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import career5 from "../../assets/images/career/career5.png";
import career6 from "../../assets/images/career/career6.png";

function Career() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <section className="Carees-main">
        <Container>
          <Row>
            <Col md={12}>
              <div className="Carees-heading text-center" data-aos="fade-up">
                <h6>Let’s get started</h6>
                <h2>Are you ready for a better, more productive business?</h2>
                <p>
                  Career and Culture at Impetrosys We Provide All Your Needs!
                  Software Consultant || IT Expert || Online Business Solution
                  || Mobile & Web Apps || Products Development
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="career-img101">
          <img src={ourproduct1} alt="" />
        </div>
      </section>
      <section className="Career-second">
        <Container>
          <Row>
            <Col md={8} sm={12}>
              <div className="carres-second-head">
                <h2>Meet the Impetrosys Constellation</h2>
                <div className="bar"></div>
                <p className="mt-3">
                  At IMPETROSYS, we recognize that exceptional talent stands out from the rest, and that the demand for sustainable speed has never been higher. Our culture fosters career-integrated hyper-learning and builds extraordinary relationships, driving impactful results.
                </p>
                <br></br>
                <p>
                  Join our network of diverse, exceptional individuals who seek equality and challenge, driven by a continuous pursuit of new ideas and learning.
                </p>
                <br></br>
                <p>
                  IMPETROSYS comprises the brightest minds working with cutting-edge technologies, dedicated to creating meaningful, positive changes in an increasingly digital world.
                </p>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="career-img101">
                <img src={homeabut} alt="img" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Vacancy />
      <section className="career-third">
        <Container>
          <Row>
            <Col md={5} sm={12}>
              <img
                className="career-img101"
                src={career5}
                alt="ImpactLeftIMG"
              />
            </Col>
            <Col md={7} sm={12}>
              <div className="Careers-forth">
                <h3>Benefit’s</h3>
                <p>
                  In today's technology sector, professionals understand the necessity of future-proofing their skills. Traditional learning methods alone are insufficient. Digital innovations, evolving digital-native needs, and a young workforce seeking impactful work have transformed the nature of work.
                </p>
                <p>
                  At IMPETROSYS, we align your career goals with strategic skills that benefit everyone in our community. Our Hyperlearning workplace is built on four core principles:
                </p>
                <ul>
                  <li>Free spirit and emotional positivity</li>
                  <li>Agile self-determination</li>
                  <li>Psychological and physical safety</li>
                  <li>Trust, transparency, and open collaboration</li>
                </ul>
                <p>
                  We utilize career-oriented skilling models and harness collective intelligence, supported by technology, to enable continuous learning, unlearning, and relearning at a rapid pace and scale.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="career15-text">
        <Careerform />
      </section>
      <section className="jointalentCommunity">
        <Container>
          <Row>
            <Col md={8} sm={12}>
              <div className="career-tt11 mt-5">
                <h2>Join Our Talent Community</h2>
                <p>
                  Stay engaged by becoming part of our network. Share your email and a brief introduction, and we will keep you updated on upcoming opportunities that align with your interests.
                </p>
                <button type="Submit" className="main-btn btn btn-primary mt-4">
                  <a href="/#">Join now
                  </a></button>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <img
                data-aos="fade-up"
                className="img-fluid cree-image-team"
                src={career6}
                alt="JoinTalentComunity"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Career;
