import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Container } from "react-bootstrap";
import { Row, Col, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Careerform() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const showAlert = (message) => {
    Swal.fire({
      title: "Thank You !",
      text: message,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  // toast message function
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastMessageone = (data) => {
    toast.error(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  //this is uses for the pdf/image upload in database.
  const [resumefile, setResumefile] = useState("");
  const fileInputRef = useRef(null);

  // image upload validation function 
  const upload = (event) => {
    const imageFile = event.target.files[0];
    setResumefile(imageFile);
    if (!imageFile) {
      showToastMessage("Please select a file");
      return false;
    } else if (!imageFile.name.match(/\.(jpg|jpeg|png|PNG|JPG|JPEG|doc|docx|pdf|txt)$/)) {
      showToastMessage("Please select only .pdf, .txt, .doc");
      return false;
    }
  };

  function handleSubmit(values) {
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("job", values.job);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("message", values.message);
    bodyFormData.append("file", resumefile);
    const url = `${process.env.REACT_APP_BASE_URL}/applyforjob`;
    axios
      .post(url, bodyFormData, {
        "Content-Type": "multipart/form-data",
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          setResumefile("")
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
          showAlert(response.data.message)
        }
        else if (response.data.status_code === 201) {
          showToastMessageone(response.data.message.name);
          showToastMessageone(response.data.message.email);
          showToastMessageone(response.data.message.job);
          showToastMessageone(response.data.message.phone);
          showToastMessageone(response.data.message.message);
          showToastMessageone(response.data.message.file);
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      job: "",
      message: "",
      file: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      phone: Yup.string()
        .required(" Required")
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Phone number is not valid"
        ),
      job: Yup.string()
        .required("Required!"),

      message: Yup.string()
        .required("Required!"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
      setResumefile("")
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      formik.resetForm();
    },
  });

  return (
    <section id="contact" className="contacus">
      <Container>
        <div className="section_title mb-4 touct-text" data-aos="zoom-in">
          <h2>
            <span>Apply Now</span>
          </h2>
          <p className="section_subtitle mx-auto">How May We Help You!</p>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={6} sm={6}>
              <label className="form_contact_field">Name</label>
              <Form.Group className="mb-3">
                <Form.Control
                  className="form-control-1"
                  type="text"
                  name="name"
                  placeholder="Enter Your Name"
                  autoComplete="off"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="errormessage">{formik.errors.name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6} sm={6}>
              <label className="form_contact_field">Email</label>
              <Form.Group className="mb-3">
                <Form.Control
                  className="form-control-1"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="errormessage">{formik.errors.email}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6} sm={6}>
              <label className="form_contact_field">Phone</label>
              <Form.Group className="mb-3">
                <Form.Control
                  className="form-control-1"
                  type="phone"
                  name="phone"
                  country="US"
                  placeholder="Enter Your Mobile Numner"
                  autoComplete="off"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <p className="errormessage">{formik.errors.phone}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6} sm={6}>
              <label className="form_contact_field">
                Apply for the job
              </label>
              <Form.Group className="mb-3">
                <Form.Control
                  className="form-control-1"
                  type="text"
                  name="job"
                  placeholder="Enter Your Job Title"
                  autoComplete="off"
                  value={formik.values.job}
                  onChange={formik.handleChange}
                />
                {formik.errors.job && formik.touched.job && (
                  <p className="errormessage">{formik.errors.job}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <label className="form_contact_field mt-1">Message</label>
              <Form.Control
                className="form-control-1"
                as="textarea"
                placeholder="Enter Your Message"
                style={{ height: "125px" }}
                type="message"
                name="message"
                autoComplete="off"
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
              />
              {formik.errors.message && formik.touched.message && (
                <p className="errormessage">{formik.errors.message}</p>
              )}
            </Col>
            <Col md={12}>
              <div className="field file-upload">
                <label htmlFor="uploadfile" className="form_contact_field mt-3">Upload Resume</label>
                <input
                  type="file"
                  className="form-control-1"
                  ref={fileInputRef}
                  id="uploadfile"
                  onChange={(e) => {
                    upload(e);
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className="form-bt mt-4">
            <Button type="Submit" className="main-home">
              APPLY NOW
            </Button>
          </div>
        </Form>
        <ToastContainer />
      </Container>
    </section>
  );
}

export default Careerform;
