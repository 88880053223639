import React, { useEffect } from "react";
import "./Industri.css";
import Industry from "../Homes/Industry/Industry";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaSortUp,
  FaConnectdevelop,
  FaJsfiddle,
  FaMedapps,
} from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";

function Industri() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <section className="industries-0">
        <Container>
          <Row>
            <Col md={12}>
              <div className="industries-00">
                <div className="indtext-0 text-center" data-aos="zoom-in">
                  <h2>
                    Focus is having the unwavering attention to complete what
                    you set out to do.
                  </h2>
                  <p className="text-center text-white">
                    The sad thing is the majority of people have no clue about
                    what they truly want. They have no clarity. When asked the
                    question, responses will be superficial at best, and at
                    worst, will be what someone else wants for them.
                  </p>
                </div>
                <div className="circle">
                  <FaSortUp />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Industry />
      <section className="ind-7">
        <Container>
          <Row>
            <div className="ind-text-5">
              <h2>Smart Strategy  &  Excellent Performance:</h2>
              <div className="bar"></div>
              <p className="mt-2">
              Our process ensures high productivity and profitability for your solution.
              </p>
            </div>
            <Col md={4}>
              <div className="indt" data-aos="fade-up" data-aos-delay="400">
                <div className="feature-info-icon">
                  <i>
                    <FaMedapps />
                  </i>
                </div>
                <div className="industries-text-39">
                  <h5>Data Analysis:</h5>
                  <p>
                  We conduct comprehensive data collection and analysis to identify your institution’s current strengths and differentiators.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="indt" data-aos="fade-up" data-aos-delay="600">
                <div className="feature-info-icon">
                  <i>
                    <FaJsfiddle />
                  </i>
                </div>
                <div className="industries-text-39">
                  <h5>Planning:</h5>
                  <p>
                  We collaborate with you to develop a plan that is actionable, feasible, and tailored to your needs.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="indt" data-aos="fade-up" data-aos-delay="800">
                <div className="feature-info-icon">
                  <i>
                    <FaConnectdevelop />
                  </i>
                </div>
                <div className="industries-text-39">
                  <h5>Delivering Results:</h5>
                  <p>
                  In this phase, we provide expertise as needed and support select initiatives to achieve your desired outcomes.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="delevery-text">
        <Container>
          <div className="ind-text-31 mb-3">
            <h2>Delivering Results for Organizations
            </h2>
          </div>
          <Row>
            <Col md={7}>
              <div className="ind-text-33">
                <p>
                  In the realm of IT, delivering results starts with a clear, strategic approach. It’s essential to recognize the excitement and optimism that often accompany the successful implementation of IT solutions. This positive energy underscores a fundamental truth: success in IT is a process, not a matter of luck or chance.
                  Our approach to IT success is straightforward. It’s about understanding the unique needs of your organization and following a methodical process to address them. By leveraging our expertise, advanced technologies, and a commitment to excellence, we ensure that your IT solutions drive tangible results and contribute to your organization’s overall success.
                </p>
              </div>
            </Col>
            <Col md={5}>
            <div className="indus-box-right">
            <div className="ind-text-32">
                <h1>New Approach to Business Growth:</h1>
                <p>Discover an innovative method to expand your business.</p>
              </div>
              <div className="ind-text-32 new">
                <h1>Enhanced Value:</h1>
                <p>We provide added value to elevate your business.</p>
              </div>
              <div className="ind-text-32 new1">
                <h1>Recognized Excellence:</h1>
                <p>
                Experience award-winning website design and creative digital agency services.
                </p>
              </div>
            </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Industri;
