import React,{useState, useEffect} from 'react';
import "./footer.css";
import { NavLink} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import facebook from "../../assets/images/footer/facebook.png";
import whatsapp from "../../assets/images/footer/whatsapp.png";
import instagram from "../../assets/images/footer/instagram.png";
import youtube from "../../assets/images/footer/twitter (2).png";
import linkedin from "../../assets/images/footer/linkedin.png";
import logo from '../../assets/images/logo.png';
import { Container, Row, Col } from "react-bootstrap";
import Logoslider from "../Logoslider/Logoslider";
import axios from "axios";

function Footer() {
  const [footerdata, setFooterData] = useState({})

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getwebsitesetting`)
      .then((response) => {
        if (response.data.status_code == 200) {
          setFooterData(response.data.websitesettings)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])


  return (
    <>
    <Logoslider/>
        <div className="footer__wrapper">
          <Container>
            <Row>
              <Col md={12}>
              <img src={logo} className="footer__logo" alt="logo" />
               <p className="footer__desc">
               IMPETROSYS, established over a decade ago, aims for global IT leadership by delivering high-quality custom web and mobile solutions for businesses across all industries.
               </p>
               </Col>
               <p className="footer__copyright">
               Copyright ©2024 <strong> IMPETROSYS.</strong> All rights reserved
              </p>
               <div className='social'>
               <ul>  
               <li>
               <NavLink to="/career" className="Footer_link">
                Career
                </NavLink>
                  </li>||
                  <li>
                  <NavLink to="/contact" className="Footer_link" >
                  Contact
                  </NavLink>
                  </li>
                  <li>
                    <a href={footerdata.facebook_url} target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>
                  <li>
                  <a href={footerdata.whatsapp_url} target="_blank">
                  <img src={whatsapp}/>
                    </a>
                  </li>
                  <li>
                  <a href={footerdata.instagram_url} target="_blank">
                    <img src={instagram}/>
                    </a>
                  </li>
                  <li>
                  <a href={footerdata.twitter_url} target="_blank">
                    <img src={youtube}/>
                    </a>
                  </li>
                  <li>
                  <a href={footerdata.linkedin_url} target="_blank">
                  <img src={linkedin}/>
                    </a>
                  </li>
                </ul>
               </div>
            </Row>
          </Container>
      </div>
      <ScrollToTop smooth color="#fff" 
      style={{background:"#00adb8",bottom:"10px",right:"8px"}} />
     </>
  );
}

export default Footer;
