import React from "react";
import "./Industry.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import secure from "../../../assets/images/home/secure.png";
import more from "../../../assets/images/home/more.png";
import event from "../../../assets/images/home/event.png";
import food from "../../../assets/images/home/food.png";
import graduation from "../../../assets/images/home/graduation.png";
import restaurant from "../../../assets/images/home/restaurant.png";
import pumps from "../../../assets/images/home/pumps.png";
import demand from "../../../assets/images/home/demand.png";
import cart from "../../../assets/images/home/cart.png";
import truck from "../../../assets/images/home/truck.png";
import healthcare from "../../../assets/images/home/healthcare.png";
import icon1 from "../../../assets/images/home/icon1.png";

export default function Industry() {

  return (
    <>
      <section className="work-category">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="common-heading ptag text-center" data-aos="zoom-in">
                <h2><strong>Industries</strong> We Serve</h2>
                <div className="bar"> </div>
                <p>
                  We are committed to serving a diverse range of industries, providing tailored digital solutions to effectively organize and streamline their operations.
                </p>
              </div>
            </Col>
          </Row>
          <div className="industry-25 mt-4">
            <Row>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={secure} alt="img" />
                  <h6>Real estate</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={graduation} alt="img" />
                  <h6>Education</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={truck} alt="img" />
                  <h6>Transport</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={demand} alt="img" />
                  <h6>On-Demand</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={event} alt="img" />
                  <h6>Event</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={cart} alt="img" />
                  <h6>eCommerce</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={healthcare} alt="img" />
                  <h6>Healthcare</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={restaurant} alt="img" />
                  <h6>Restaurant</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={icon1} alt="img" />
                  <h6>Finance</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={food} alt="img" />
                  <h6>Grocery</h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={pumps} alt="img" />
                  <h6>Music & Entertainment  </h6>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div className="industry-workfor">
                  <img src={more} alt="img" />
                  <h6>Many More..</h6>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};